.icon-container {
    height: 216px;
    width: 216px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-container > img {
    width: 100%;
}

.createdAt{
    font-size: 18px;
}

.token-title {
    font-size: 32px;
}

.buy-now-button {
    padding: 10px 20px !important;
    width: 300px !important;
    height: 53px !important;
    min-width: 125px;
    font-size: 22px;
    font-weight: 500;
}

.page-content {
    margin-top: -108px;
}

.catchment-bar {
    height: 26px;
}

.v-bar {
    top: 6px;
    height: 75%;
    border-right: 1.5px dotted black;
    position: relative;
}

.cap-text {
    top: 26px;
    height: 75%;
    position: relative;
    display: flex;
    width: 5px;
    text-align: center;
}

.details-bar > button {
    font-size: 19px;
}

@media only screen and (max-width: 768px) {
    .page-content {
        margin-top: -87px;
    }

    .icon-container {
        height: 172px;
        width: 172px;
    }

    .icon-container > img {
        height: 132px;
        width: 132px;
    }

    .token-title {
        font-size: 24px;
    }

    .details-bar {
        font-size: 0.5rem;
    }

    .details-bar > button {
        font-size: 16px;
    }
}

@media only screen and (max-width: 650px) {

    .details-bar {
        overflow-x: auto;
    }

}