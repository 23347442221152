.banner-icon-container {
    height: 216px;
    width: 216px;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.banner-icon-container > .banner-inner-icon-bordered {
    width: 152px;
}

.banner-icon-container > .banner-inner-icon-borderless {
    width: 100%;
}

.banner-content-title {
    font-size: 32px;
}

.banner-content {
    margin-top: -120px;
}

@media only screen and (max-width: 640px) {
    .banner-icon-container > .banner-inner-icon-borderless {
        width: 75%;
    }
    
    .banner-icon-container {
        width: 75%;
    }
}

@media only screen and (max-width: 768px) {
    .banner-content {
        margin-top: -87px;
    }

    .banner-icon-container {
        height: 172px;
        width: 172px;
    }

    .banner-icon-container > .banner-inner-icon-bordered {
        width: 132px;
    }

    .banner-content-title {
        font-size: 24px;
    }
}