.account-form-input > div > label {
  top: 7px !important;
}

.account-form-input {
  margin: 20px 0px !important;
  margin-bottom: 0px !important;
  padding: 12px 0px !important;
  border-color: #bdbdbd;
  word-break: break-all;
}

.margin-bottom-zero {
  margin-bottom: 0px !important;
}

.text-uppercase > div > input {
  text-transform: uppercase !important;
}

.bank-account-content {
  padding: 1.25rem !important;
}

@media only screen and (min-width: 768px) {
  .account-form {
    width: 640px !important;
  }
}

@media only screen and (max-width: 640px) {
  .hidden-on-mobile {
    display: none !important;
  }
}
