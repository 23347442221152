.sell-token {
    height: 72%;
    animation: slide-down-up 0.5s;
    -webkit-animation: slide-down-up 0.5s;
}

.sell-token-close {

  animation: slide-up-down 0.5s;
  -webkit-animation: slide-up-down 0.5s;
}

@media only screen and (max-height: 760px) {
    .sell-token {
        height: auto;
    }
}