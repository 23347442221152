.documents-btn {
    box-shadow: none !important;
    color: white !important;
    height: 40px !important;
    width: 174px !important;
    padding: 0px !important;
    font-size: 18px;
    font-weight: 500;
}

@media only screeen and (min-width: 640px) {
    .cliente-doc-status-card {
        width: 146px !important;
    }
}