.filter-modal-btn {
    height: 60px !important;
    width: 100% !important;
    border-radius: 0 !important;
    font-size: 18px !important;
    font-weight: 500px;
    margin: 0px !important;
}

.filter-modal-dialog {
    width: 720px !important;
    padding: 33px 144px 96px 144px !important;
}

@media only screen and (max-width: 650px) {
    .filter-modal-dialog {
        width: 100% !important;
        padding: 5% !important;
        margin: 0px 10px;
    }
}