.resume-card .card-title {
    font-size: 24px;
    line-height: 22px;
    margin-bottom: 16px;
}

.resume-card .card-value p{
    font-size: 34px;
    line-height: 2rem;
}

@media screen and (max-width: 768px) {
    .resume-card .card-title {
        margin-bottom: none !important;
        font-size: 20px;
    }

    .resume-card .card-value p{
        font-size: 28px;
        line-height: 2rem;
    }
}

