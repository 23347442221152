.border-3 {
  border: 3px solid;
}

.border-05 {
  border: 0.5px solid;
}

.markdown-wrapper hr {
  @apply mb-4;
}

.markdown-wrapper h1 {
  @apply mb-4 text-2xl font-bold;
}

.markdown-wrapper h2 {
  @apply mb-4 text-2xl  font-bold;
}

.markdown-wrapper h3 {
  @apply mb-4 text-lg  font-bold;
}

.markdown-wrapper h4 {
  @apply mb-4 text-base  font-bold;
}

.markdown-wrapper h5,
.markdown-wrapper h6 {
  @apply mb-4 text-sm  font-bold;
}

.markdown-wrapper h4 {
  @apply mb-4 text-base;
}

.markdown-wrapper p {
  @apply mb-4 text-base;
}
