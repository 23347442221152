.hover-dark:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.dashboard-negotiate-btn {
    color: white !important;
    width: 122px !important;
    height: 19px !important;
    display: flex;
    align-items: center;
}

.token-icon {
    width: 54px;
    height: 54px;
}

.card-text {
    font-size: 22px;
}

.highlight-card {
    margin: 10px 0px;
}