.filter-section-input {
    padding: 16px;
}

.filter-modal-input {
    padding: 10px 5px;
    padding-right: 20px;
}


.filter-modal-input > select {
    font-size: 20px;
    color: #828282 !important;
}
