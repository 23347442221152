.buy-tokens-btn {
    height: 53px;
    width: 307.5px !important;
    min-width: 125px;
    font-size: 22px !important;
    font-weight: 500 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25) !important;
    margin-bottom: 3px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: border-box !important;
}

.hover-dark:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.my-tokens {
    margin-left: 0.5rem;
    font-weight: 500;
    font-size: 19px;
}

.show-offer-btn {
    width: 136px !important;
}