.login-btn {
    height: 51px;
    font-size: 18px !important;
    font-weight: 500 !important;
}

.signin-form > div {
    border: 0.7px solid #BDBDBD;
}

.signin-page-container {
    width: 320px;
}