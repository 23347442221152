.card-button {
    padding: 2px 0 !important;
    height: 34px !important;
    width: 150px !important;
    min-width: 125px;
    font-size: 0.875rem;
    box-shadow: none !important;
}

.details-btn:hover {
  filter: brightness(0.95);
}

@media only screen and (max-width: 640px) {
    .card-button {
      width: 50% !important;
      height: 40px !important;
      font-size: 18px !important;
    }
  }