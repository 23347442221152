.amount-input {
  width: 308px;
  height: 58px;
  border: 1px 0 solid rgb(167, 3, 3);
}

.amount-input > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.25rem;
}

.amount-input-btn {
  width: 58px;
  height: 58px;
  cursor: pointer;
}

.amount-input-btn > p {
  font-size: 36px;
}

.buy-token {
    overflow-y: unset !important;
    height: 100%;
    animation: slide-down-up 0.5s;
    -webkit-animation: slide-down-up 0.5s;
}

.buy-token-close {

  animation: slide-up-down 0.5s;
  -webkit-animation: slide-up-down 0.5s;
}

@media only screen and (max-width: 640px) {
    .buy-token {
        height: 100%;
    }
}

@keyframes slide-up-down {
  0% {
      transform: translateY(0%);
      -webkit-transform: translateY(0%);
      -moz-transform: translateY(0%);
      -ms-transform: translateY(0%);
      -o-transform: translateY(0%);
  }
    100% {
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
        -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
        -o-transform: translateY(100%);
  }
}

@keyframes slide-down-up {
  0% {
      transform: translateY(100%);
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
  }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
  }
}