.outline.big input:focus-within ~ label,
.outline.big input:not(:placeholder-shown) ~ label {
    /* @apply transform scale-75 -translate-y-4 z-0 ml-3 px-1 py-0;   */
    transform: translateY(-25px) scale(0.85);
}

.outline.big input:focus-within ~ label,
.outline.big input:not(:placeholder-shown) ~ label:hover {
    transform: translateY(-25px) scale(0.85);
}

.outline input:focus-within ~ label,
.outline input:not(:placeholder-shown) ~ label {
    /* @apply transform scale-75 -translate-y-4 z-0 ml-3 px-1 py-0;   */
    transform: translateY(-18px) scale(0.75);
}

.outline input:focus-within ~ label,
.outline input:not(:placeholder-shown) ~ label:hover {
    transform: translateY(-18px) scale(0.75);
}
