.highlight-card .card-btn {
  height: 19px !important;
  box-shadow: none !important;
  border: none;
}

.highlight-card .card-text {
  font-size: 22px;
}

.highlight-card .token-icon {
  width: 54px;
  height: 54px;
}