.skeleton-btn {
    height: 53px;
    width: 320px !important;
    min-width: 125px;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 16px !important;
    box-sizing: border-box !important;
    border: none;
}

.skeleton-btn2 {
    margin-top: 46px !important;
    margin-bottom: 54px !important;
}

.skeleton-btn2-alone {
    margin: 54px 0 !important;
}

@media only screen and (max-width: 640px) {
    .skeleton-btn {
        width: 90% !important;
    }
}