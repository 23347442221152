.pie-chart {
    width: 137px;
    height: 137px;
}

.asset-negotiate-btn {
    justify-content: center;
    width: 128px !important;
    height: 30px !important;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    border: none;
    box-shadow: none !important;
}

.asset-card-title {
    font-size: 22px;
    line-height: 26px;
}