header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    padding: 18px 20px;

    background-color: #24252a;
    color: white;

    height: 56px;

    z-index: 10;
}
.cta {
    margin-left: auto;
}
.nav__links {
    list-style: none;
}
.nav__links .li {
    display: inline-block;
    padding: 0px 20px;
    transition: all 0.3s ease 0s;
}
.nav__links .li:hover {
    font-weight: bolder;
}

.routeActive {
    font-weight: bold;
}

.logo {
    cursor: pointer;
}

.userIcon {
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 20px;
}

.eyeToggleIcon {
    width: 22px;
    margin-left: 5px;
    opacity: 0.5;
}

.eyeToggleIcon-mobile {
    width: 18px;
    opacity: 0.5;
    z-index: 99;
}


.eyeToggleIcon:hover {
    opacity: 0.7;
}

.leftBar {
    margin-left: auto;
}

@media (max-width: 768px) { 
    .items-menu {
        display: none !important;
    }
}